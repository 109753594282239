import blog1 from '../images/kadirov.jpg'
import blog2 from '../images/samar.jpg'
import blog3 from '../images/upwork.jpg'
import blog4 from '../images/Mohirdev.jpg'

const blogs = [
    {   
        id: 1,
        title: 'My favorite programming theory is with Akmal Kadyrov',
        image: blog1,
        youtube: 'https://youtube.com/kadirovDev',
        web: 'https://youtube.com/kadirovDev',
        telegram: 'https://t.me/kadirovDev',
        instagram: 'https://www.instagram.com/kadirovDev/'
        
    },
    {
        id: 2,
        title: 'My virtual JavaScript teacher is Samar Badriddinov. Really great content"  ',
        image: blog2,
        youtube: 'https://youtube.com/samarbadriddinov',
        web: 'https://youtube.com/samarbadriddinov',
        telegram: 'https://t.me/samarbadriddinov',
        instagram: 'https://instagram.com/samar.badriddinov'

    },
    {
        id: 3,
        title: 'A great platform for beginners to get started',
        image: blog3,
        youtube: 'https://www.youtube.com/channel/UCvxGFOnwUBDHHcxuPqhe4CQ',
        web: 'https://www.upwork.com/',
        telegram: 'https://www.facebook.com/upwork',
        instagram: 'https://www.instagram.com/upworkinc/'
    },
    {
        id: 4,
        title: 'The first perfect programming lesson platform in Uzbek language',
        image: blog4,
        youtube: 'https://www.youtube.com/channel/UCZymAOPM_aN1q9vSnw3Drng',
        telegram: 'https://t.me/mohirdev',
        instagram: 'https://www.instagram.com/mohirdev/',
        web: 'https://mohirdev.uz/'
    }
]

export default blogs;